import {atom} from 'recoil';

export const BundleAtom = atom({
    key: 'selected', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
  });

  export const minutesBalance = atom({
    key: 'minutes', // unique ID (with respect to other atoms/selectors)
    default: "-", // default value (aka initial value)
  });

  export const dialingNumber = atom({
    key: 'number', // unique ID (with respect to other atoms/selectors)
    default: "-", // default value (aka initial value)
  });

  

