import React, { useEffect, useState } from 'react';
import Phone from '../images/phoneicon.png'
import '../App.css';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Footer from '../components/Footer'
import { getAxiosInstance } from '../axios'
import FreeMinutesModal from '../components/Modals/FreeMinutesModal'
import HeaderDetails from '../components/HeaderDetails'
import ReaderFeed from '../components/ReaderFeed'
import { NumberButton1, NumberButton2, NumberButton3 } from '../components/NumberButtons'
import FloatingMenu from '../components/FloatingMenu'
import { minutesBalance, dialingNumber } from '../Atoms';
import { useRecoilState } from 'recoil';

function Account() {

  const [loading, setLoading] = useState(true)
  const [email, setEmail] = useState("")
  const [pin, setPin] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [address1, setAddress1] = useState("")
  const [address2, setAddress2] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [zip, setZip] = useState("")
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [minutes, setMinutes] = useRecoilState(minutesBalance);
  const [dialingNo, setDialingNo] = useRecoilState(dialingNumber);
  const [terms, setTerms] = useState("")


  const injectSDK = (currency) => {
    
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    getAxiosInstance(false, window.location.hostname).get('/profile')
      .then((response) => {
        setEmail(response.data.email)
        setPin(response.data.pin)
        setAccountNumber(response.data.accountNo)
        setAddress1(response.data.address.firstLine)
        setAddress2(response.data.address.secondLine)
        setState(response.data.address.countyOrState)
        setCity(response.data.address.city)
        setZip(response.data.address.areaCode)
        setLoading(false);
      }).catch((err) => {
        //Handle error
        setLoading(false);
        console.log(err)
      })

    const removeSpaces = (string) => {
      return string.replace(/\s/g, '')
    }

    getAxiosInstance(false, window.location.hostname).get('/service')
      .then((response) => {
        setDialingNo(removeSpaces(response.data.dialingNumber))
        setTerms(response.data.numberTerms)
        injectSDK(response.data.currencyCode)
      }).catch((err) => {      
        console.log(err)
      })
  }, []);


  const updateAddress = () => {
    getAxiosInstance(false, window.location.hostname).patch('/me/address', { "firstLine": address1, "secondLine": address2, "stateOrCounty": state, "city": city, "areaCode": zip })
      .then((response) => {
        alert("Address Saved")
      }).catch((err) => {     
        console.log(err)
      })
  }

  return (
    <div className="App">

      <div>
        <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
          <Row style={{ backgroundColor: '#C16854' }}><HeaderDetails redeem={() => setShow(true)} history={history} /></Row>

          <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%' }}>
            <Col xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left', minHeight: 'calc(100% - 150px)' }}>
              <Container fluid style={{ padding: 0, height: '100%', width: '100%' }}>
                <Row style={{ height: '100%' }}>
                  <Col xs={12} md={6} style={{ backgroundColor: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container >
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>How to have a reading</font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px' }}><Col xs={12} md={11} lg={8} xl={6}><NumberButton1 /></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>Take a note of the pin number of the psychic you wish to speak to.</font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px' }}><Col xs={12} md={11} lg={8} xl={6}><NumberButton2 /></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>Call from your mobile or landline</font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }}><Col xs={12} md={11} lg={8} xl={6} > <a href={"tel:" + dialingNo}><Button style={{ backgroundColor: '#84D631', border: 'none', paddingRight: '50px', paddingLeft: '50px', width: '100%' }}><div style={{ display: 'inline', paddingRight: '10px' }}><img src={Phone} style={{ height: '10px', width: '10px' }} /></div><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white', fontWeight: '700' }}>{dialingNo}</font></Button></a></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '10px', color: '#464646', fontWeight: '400' }}>{terms} </font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px' }}><Col xs={12} md={11} lg={8} xl={6}><NumberButton3 /></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '40px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>Press 1 to select that you have an existing account and enter your account details shown on the right.</font></Col></Row>




                      <Row className="d-block d-md-none" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Number</font></Form.Label>
                          <Form.Control type="text" disabled value={accountNumber} style={{ border: 'none', backgroundColor: 'white' }} />
                        </Form.Group></Col></Row>

                      <Row className="d-block d-md-none" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Pin</font></Form.Label>
                          <div style={{ display: 'inline' }}><Form.Control disabled type="text" value={pin} style={{ border: 'none', backgroundColor: 'white' }} /></div>
                        </Form.Group>
                      </Col></Row>
                      <Row className="d-flex d-md-none" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => history.push('/bundles')}>Purchase minutes ></font></Button></Col></Row>
                      <Row className="d-flex d-md-none" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '40px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => setShow(true)} >Redeem free minutes ></font></Button></Col></Row>
                    </Container>
                  </Col>
                  <Col xs={12} md={6} style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container>
                      <Row className="d-none d-md-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Number</font></Form.Label>
                          <Form.Control disabled type="text" value={accountNumber} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row className="d-none d-md-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Pin</font></Form.Label>
                          <div style={{ display: 'inline' }}><Form.Control disabled type="text" value={pin} style={{ border: 'none', backgroundColor: '#F8F5F5' }} /></div>
                        </Form.Group>
                      </Col></Row>
                      <Row className="d-none d-md-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => history.push('/bundles')} >Purchase minutes ></font></Button></Col></Row>
                      <Row className="d-none d-md-flex" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '40px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => setShow(true)}>Redeem free minutes ></font></Button></Col></Row>
                    </Container>
                  </Col>

                  <Col xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container fluid>
                      <ReaderFeed Number={dialingNo} accountNumber={accountNumber} />
                    </Container></Col>

                  <Col className="d-flex d-md-none" xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container >
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px', paddingBottom: '50px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>My Account</font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Number</font></Form.Label>
                          <Form.Control type="text" disabled value={accountNumber} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Pin</font></Form.Label>
                          <Form.Control type="text" disabled value={pin} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Email Address</font></Form.Label>
                          <Form.Control type="text" disabled value={email} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                    </Container>
                  </Col>
                  <Col className="d-flex d-md-none" xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '50px' }}>
                    <Container>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Address Line 1</font></Form.Label>
                          <Form.Control type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>

                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Address Line 2</font></Form.Label>
                          <Form.Control type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Town / City</font></Form.Label>
                          <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>State / County</font></Form.Label>
                          <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Zip / Postal Code</font></Form.Label>
                          <Form.Control type="text" value={zip} onChange={(e) => setZip(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '40px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => updateAddress()}>Update Address</font></Button></Col></Row>

                    </Container>
                  </Col>


                  <Col className="d-none d-md-flex" xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container >
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px', paddingBottom: '50px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>My Account</font></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Number</font></Form.Label>
                          <Form.Control type="text" disabled value={accountNumber} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Account Pin</font></Form.Label>
                          <Form.Control type="text" disabled value={pin} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '150px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Email Address</font></Form.Label>
                          <Form.Control type="text" disabled value={email} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                    </Container>
                  </Col>


                  <Col className="d-none d-md-flex" xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Container>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px', paddingTop: '280px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Address Line 1</font></Form.Label>
                          <Form.Control type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>

                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Address Line 2</font></Form.Label>
                          <Form.Control type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Town / City</font></Form.Label>
                          <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>State / County</font></Form.Label>
                          <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}><Col xs={12} md={11} lg={8} xl={6}>
                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Zip / Postal Code</font></Form.Label>
                          <Form.Control type="text" value={zip} onChange={(e) => setZip(e.target.value)} style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
                        </Form.Group></Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '40px' }}><Col xs={12} md={11} lg={8} xl={6}><Button style={{ border: 'none', width: '100%' }} className="btn-primary"><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }} onClick={() => updateAddress()}>Update Address</font></Button></Col></Row>
                    </Container>

                  </Col>

                </Row>
              </Container>
            </Col>

            <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Footer />
            </Col>

          </Row>

          <Modal show={show} onHide={() => setShow(false)} close={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
            <FreeMinutesModal close={() => setShow(false)} />
          </Modal>

        </Container>
      </div>

      <div style={{ position: 'fixed', bottom: 0, width: '100%', padding: 0 }}>
        <FloatingMenu buy={() => history.push("/bundles")} />
      </div>


    </div>
  );
}

export default Account;
