import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CreateAccount from './Pages/CreateAccount';
import LogIn from './Pages/Login'
import Account from './Pages/Account'
import Bundles from './Pages/Bundles'
import Purchase from './Pages/Purchase'
import Forgot from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';
import {RecoilRoot,atom,selector,useRecoilState,useRecoilValue} from 'recoil';

ReactDOM.render(
  <BrowserRouter>
   <RecoilRoot>
   <Route exact path='/' component={App}/> 
   <Route path='/create' component={CreateAccount}/> 
   <Route path='/account' component={Account}/> 
   <Route path='/bundles' component={Bundles}/> 
   <Route path='/login' component={LogIn}/> 
   <Route path='/purchase' component={Purchase}/> 
   <Route path='/forgot' component={Forgot}/> 
   <Route path='/reset-password' component={ResetPassword}/> 
   </RecoilRoot>
    </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
