import React, { useEffect, useState } from 'react'
import '../App.css';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { getAxiosInstanceFeed, getAxiosInstanceFeedStatus } from '../axios'
import Stars from '../components/stars'

function ReaderFeed(props) {

  const [readers, setReaders] = useState([])

  useEffect(() => {
    console.log(props.Number)
    getFeed(true)
    setInterval(getFeed, 5000)
  }, []);


  const getFeed = () => {
    getAxiosInstanceFeed().get()
      .then((response) => {
        var array = []
        for (const [index, value] of response.data.ReaderPools.entries()) {
          for (const [indexA, valueA] of response.data.ReaderPools[index].readers.entries()) {
            if (valueA.AvailabilityStatus !== "Logged off") {
              array.push({ "ReaderName": valueA.ReaderName, "ReaderImageLandscape": valueA.ReaderImageLandscape, "ReaderPin": valueA.ReaderPin, "TotalReadings": valueA.TotalReadings, "ReaderStarRating": valueA.ReaderStarRating, "AvailabilityStatus": valueA.AvailabilityStatus })
            }
          }
        }

        getStatus(array)
      }).catch((err) => {
        //Handle error        
        console.log(err)
      })
  }


  const getStatus = (array) => {
    getAxiosInstanceFeedStatus().get()
      .then((response) => {

        for (const [index, value] of response.data.entries()) {
          for (const [index2, value2] of array.entries()) {
            if (value2.ReaderPin === value.pin) {
              array[index2]["call-ends-at"] = value["call-ends-at"]
            }
          }
        }

        array = array.sort((a, b) => (a.AvailabilityStatus > b.AvailabilityStatus) ? 1 : -1)
        setReaders(array)

      }).catch((err) => {
        //Handle error        
        console.log(err)
      })
  }
  const toTime = (time) => {
    return Math.round((time - Date.now() / 1000) / 60)
  }

  const views = readers.map((localState) => (
    <Col xs={6} sm={5} md={3} xl={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '150px' }}>
      <Container fluid style={{ dusplay: 'flex', width: '100%', paddingBottom: '20px' }}>
        <Row><img style={{ height: '150px', width: '100%', objectFit: 'cover' }} src={localState.ReaderImageLandscape} /></Row>
        {localState.AvailabilityStatus === "Available" && <Row style={{ height: '10px', backgroundColor: '#84D631' }}></Row>}
        {localState.AvailabilityStatus === "Busy" && <Row style={{ height: '10px', backgroundColor: '#E0A013' }}></Row>}
        <Row style={{ paddingBottom: '5px' }} className="primary-color-text"><font style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: '700' }}>{localState.ReaderName}</font></Row>
        <Row><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '15px', fontWeight: '700' }}>Pin {localState.ReaderPin}</font></Row>
        <Row><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '15px', fontWeight: '700' }}>Readings {localState.TotalReadings}</font></Row>
        <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}><Stars Rating={localState.ReaderStarRating} /></Row>
        {localState.AvailabilityStatus === "Available" && <div><Row className="d-block d-sm-none"><Col xs={12} style={{ padding: 0 }}><a href={"tel:" + props.Number}><Button style={{ width: '100%', backgroundColor: '#84D631', border: 'none' }}><font style={{ fontFamily: 'Poppins', color: 'white', fontSize: '12px', fontWeight: '700', whiteSpace: 'nowrap' }}>Click to call now ></font></Button></a></Col></Row><Row className="d-none d-sm-block"><Col xs={12} style={{ padding: 0 }}><Button style={{ width: '100%', backgroundColor: '#84D631', border: 'none' }}><font style={{ fontFamily: 'Poppins', color: 'white', fontSize: '12px', fontWeight: '700', whiteSpace: 'nowrap' }}>Available ></font></Button></Col></Row></div>}
        {localState.AvailabilityStatus === "Busy" && localState["call-ends-at"] !== "" && <Row ><Col xs={12} style={{ padding: 0 }}><a href={"tel:" + props.Number}><Button style={{ width: '100%', backgroundColor: '#E0A013', border: 'none' }}><font style={{ fontFamily: 'Poppins', color: 'white', fontSize: '12px', fontWeight: '700' }}>Busy - {toTime(localState["call-ends-at"])} mins</font></Button></a></Col></Row>}
        {localState.AvailabilityStatus === "Busy" && localState["call-ends-at"] === "" && <Row ><Col xs={12} style={{ padding: 0 }}><a href={"tel:" + props.Number}><Button style={{ width: '100%', backgroundColor: '#E0A013', border: 'none' }}><font style={{ fontFamily: 'Poppins', color: 'white', fontSize: '12px', fontWeight: '700' }}>Busy</font></Button></a></Col></Row>}
      </Container>

    </Col>

  ));

  return (
    <Container fluid style={{ padding: 0 }} >
      <Row style={{ paddingTop: '50px', paddingBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={10} xl={9} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Online Psychics</font></Col></Row>
      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={10} xl={9} >
        <Container fluid style={{ width: '100%', padding: 0 }}>
          <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{views}</Row>
        </Container>
      </Col>
      </Row>
    </Container>
  )

}

export default ReaderFeed;