import React, { useEffect } from 'react';
import SignUpForm2 from '../components/SignUpForm2'
import { Container, Row, Col, Form } from 'react-bootstrap';
import Header from '../components/Header'
import { useHistory } from "react-router-dom";
import Footer from '../components/Footer'

function CreateAccount(props) {

  const history = useHistory();

  useEffect(() => {
    console.log(props)
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">

      <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <Row style={{ backgroundColor: '#C16854' }}><Header history={history} /></Row>
        <Row style={{ height: 'calc(100% - 70px)', minHeight: '600px' }}>
          <Col xs={12} md={12} style={{ backgroundColor: '#FFFFF' }}>
            <Container style={{ padding: 0 }}>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Col xs={11} sm={11} md={10} lg={8} xl={6} style={{ padding: 0 }}  >
                  <Container style={{ paddingTop: '40px' }}>
                    <Row ><Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '35px', fontWeight: 700 }}>Sign Up</font></Col></Row>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11} sm={10} md={12} ><SignUpForm2 data={props.location.state} /></Col></Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default CreateAccount;
