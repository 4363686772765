import axios from 'axios';



const getAxiosInstance = (showError, domain) => {

    console.log(domain)
    
var str = ""
    if(domain === "localhost"){
        
        str = "allstarpsychics.co.uk"
    }else{
        str = domain.substring(domain.indexOf(".") + 1);
    }
    
    axios.defaults.withCredentials=true

    const mInstance = axios.create({
        withCredentials: true,
        //baseURL: 'wallet-api.allstarpsychics.co.uk',
        baseURL: 'https://wallet-api.'+ str +'/api',
        timeout:10000
    });


    //mInstance.defaults.headers.common['Service-Provider'] = "TheBestPsychics";

    mInstance.interceptors.response.use(response => {
        return Promise.resolve(response);
     }, error => {
       console.log(error)

       if(error.code == 'ECONNABORTED' && showError !==false){
           alert("Oops, Network error")
           return Promise.reject(error);
       }
       if (error.response.status === 401) {
       
        window.location.href = '/';
    }
       return Promise.reject(error);
     });
   
    return mInstance
}





 const getAxiosInstanceLogin = (showError,domain) => {

    var str=""
    if(domain === "localhost"){
        
        str = "moonbar.com.au"
            }else{
                str = domain.substring(domain.indexOf(".") + 1);
            }

    axios.defaults.withCredentials=true

    const mInstance = axios.create({
        withCredentials: true,
        //baseURL: 'wallet-api.allstarpsychics.co.uk',
        baseURL: 'https://wallet-api.'+ str +'/api',
        timeout:10000
    });


    //mInstance.defaults.headers.common['Service-Provider'] = "TheBestPsychics";

    mInstance.interceptors.response.use(response => {
        return Promise.resolve(response);
     }, error => {
       console.log(error)

       if(error.code == 'ECONNABORTED'){
           alert("Oops, Network error")
           return Promise.reject(error);
       }
       
       return Promise.reject(error);
     });
   
    return mInstance
}




const getAxiosInstanceFeed = () => {


    const mInstance = axios.create({
        withCredentials:false,
        baseURL: 'https://feeds.allstarpsychics.co.uk/v1/full',
        timeout:10000
    });

    mInstance.interceptors.response.use(response => {
        return Promise.resolve(response);
     }, error => {
       console.log(error)

       if(error.code == 'ECONNABORTED'){
           return Promise.reject(error);
       }
       return Promise.reject(error);
     });
   
    return mInstance
}


const getAxiosInstanceFeedStatus = () => {


    const mInstance = axios.create({
        withCredentials:false,
        baseURL: 'https://feeds.allstarpsychics.co.uk/v1/short',
        timeout:10000
    });

    mInstance.interceptors.response.use(response => {
        return Promise.resolve(response);
     }, error => {
       console.log(error)

       if(error.code == 'ECONNABORTED'){
           return Promise.reject(error);
       }
       return Promise.reject(error);
     });
   
    return mInstance
}

export {getAxiosInstance, getAxiosInstanceLogin, getAxiosInstanceFeed, getAxiosInstanceFeedStatus}
