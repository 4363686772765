import React from 'react';
import '../numbers.css'

function NumberButton3(props) {


  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" className="number-circle-color-1" />
          <stop offset="1" className="number-circle-color-2" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fff" stop-opacity="0.588" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="Group_965" data-name="Group 965" transform="translate(-158 -520)">
        <g id="Group_956" data-name="Group 956" transform="translate(0.366 265.366)">
          <circle id="Ellipse_1" data-name="Ellipse 1" class="cls-1" cx="21.5" cy="21.5" r="21.5" transform="translate(157.634 254.634)" />
          <path id="Path_1703" data-name="Path 1703" class="cls-2" d="M27.372,0a21.317,21.317,0,1,1,0,42.634c-11.773,0,3.182-10.658,3.182-21.317S15.6,0,27.372,0Z" transform="translate(151.945 255)" />
        </g>
        <text id="_3" data-name="3" class="cls-3" transform="translate(180 551)"><tspan x="-8.47" y="0">3</tspan></text>
      </g>
    </svg>
  );
}
function NumberButton2(props) {


  return (

    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
      <defs>
        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" className="number-circle-color-1" />
          <stop offset="1" className="number-circle-color-2" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fff" stop-opacity="0.588" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="Group_964" data-name="Group 964" transform="translate(-158 -373)">
        <g id="Group_955" data-name="Group 955" transform="translate(0.366 118.366)">
          <circle id="Ellipse_1" data-name="Ellipse 1" class="cls-1" cx="21.5" cy="21.5" r="21.5" transform="translate(157.634 254.634)" />
          <path id="Path_1703" data-name="Path 1703" class="cls-2" d="M27.372,0a21.317,21.317,0,1,1,0,42.634c-11.773,0,3.182-10.658,3.182-21.317S15.6,0,27.372,0Z" transform="translate(151.945 255)" />
        </g>
        <text id="_2" data-name="2" class="cls-3" transform="translate(180 404)"><tspan x="-7.924" y="0">2</tspan></text>
      </g>
    </svg>

  );
}



function NumberButton1(props) {


  return (

    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
      <defs>

        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" className="number-circle-color-1" />
          <stop offset="1" className="number-circle-color-2" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fff" stop-opacity="0.588" />
          <stop offset="1" stop-color="#fff" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g id="Group_963" data-name="Group 963" transform="translate(-158 -274)">
        <g id="Group_954" data-name="Group 954" transform="translate(0.366 19.366)">
          <circle id="Ellipse_1" data-name="Ellipse 1" class="cls-1" cx="21.5" cy="21.5" r="21.5" transform="translate(157.634 254.634)" />
          <path id="Path_1703" data-name="Path 1703" class="cls-2" d="M27.372,0a21.317,21.317,0,1,1,0,42.634c-11.773,0,3.182-10.658,3.182-21.317S15.6,0,27.372,0Z" transform="translate(151.945 255)" />
        </g>
        <text id="_1" data-name="1" class="cls-3" transform="translate(175 303)"><tspan x="0" y="0">1</tspan></text>
      </g>
    </svg>

  );
}

export { NumberButton1, NumberButton2, NumberButton3 };