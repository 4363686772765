import React, { useState, useEffect } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../axios'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

function SignUpForm2(props) {

	const [email, setEmail] = useState("")
	const [password, setpassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [dd, setdd] = useState("")
	const [mm, setmm] = useState("")
	const [yyyy, setyyyy] = useState("")
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")
	const [city, setCity] = useState("")
	const [county, setCounty] = useState("")
	const [zip, setZip] = useState("")
	const [acceptedTerms, setAcceptedTerms] = useState(false)
	const [marketing, setMarketing] = useState(false)
	const [error, setError] = useState(null)
	const history = useHistory();
	const [loading, setLoading] = useState(false)
	const [checked, setChecked] = useState("")
	const [phone, setPhone] = useState("")
	const [preferredCountriesArray, setPreferedCountriesArray] = useState([])
	const [selectedCountryCode, setSelectedCountryCode] = useState("")
	const [brandServiceId, setBrandServiceId] = useState(0)

	useEffect(() => {
		console.log(props)
		setEmail(props.data.email)
		setpassword(props.data.password)
		setConfirmPassword(props.data.password2)
		setAcceptedTerms(props.data.acceptedTerms)

		getAxiosInstance(false, window.location.hostname).get('/brands/services')
			.then((response) => {
				console.log(response)
				setBrandServiceId(0)
				setPreferedCountriesArray(response.data)
				console.log(response.data[0].countryCode)
				setSelectedCountryCode(response.data[0].countryCode)

			}).catch((err) => {

			})
	}, [getAxiosInstance])

	const handleSubmit = () => {

		var re = /^([A-z,-.`']){2,32}$/;
		if (re.test(firstName) === false) {
			setError("First Name invalid.")
			return
		}

		var re = /^([A-z,-.`']){2,32}$/;
		if (re.test(lastName) === false) {
			setError("Last Name invalid.")
			return
		}

		var reserv = new Date(yyyy + "/" + mm + "/" + dd).getTime()

		if (Number.isInteger(calculate_age(reserv)) === false) {
			setError("Invalid DOB.")
			return
		}

		if (calculate_age(reserv) < 18) {
			setError("Must be over 18 years.")
			return
		}

		var re = /^.{1,128}$/;
		if (re.test(address1) === false) {
			setError("1st line of address invalid.")
			return
		}

		var re = /^.{1,128}$/;
		if (re.test(address2) === false) {
			setError("2nd line of address invalid.")
			return
		}

		var re = /^.{1,128}$/;
		if (re.test(county) === false) {
			setError("State / County invalid.")
			return
		}

		

		var re = /^.{1,128}$/;
		if (re.test(city) === false) {
			setError("City invalid.")
			return
		}

		var re = /^.{4,12}$/;
		if (re.test(zip) === false) {
			setError("Zip / Postal code invalid.")
			return
		}
		setLoading(true);
		getAxiosInstance(false, window.location.hostname).post('/signup/manual', {
			"password": password,
			"emailAddress": email,
			"lastName": lastName,
			"firstName": firstName,
			"confirmPassword": confirmPassword,
			"dob": reserv,
			"cli": '+' + selectedCountryCode + ':::' + '+' + phone,
			"address": {
				"stateOrCounty": county,
				"country": preferredCountriesArray[brandServiceId].countryCode,
				"firstLineAddress": address1,
				"secondLineAddress": address2,
				"city": city,
				"areaCode": zip
			},
			"acceptTerms": acceptedTerms,
			"marketing": marketing,
			"brandServiceId": preferredCountriesArray[brandServiceId].id
		})
			.then((response) => {
				console.log(response)
				console.log("Go to bundles")
				history.push("/bundles")
			}).catch((err) => {
				//Handle error
				setLoading(false);
				if (err.response.data.Reason !== undefined) {
					setError(err.response.data.Reason)
				} else {
					alert("Oops, something went wrong.")
				}
			})
	}

	function calculate_age(dob) {
		var diff_ms = Date.now() - dob;
		var age_dt = new Date(diff_ms);
		return Math.abs(age_dt.getUTCFullYear() - 1970);
	}

	const firstNameChange = (value) => {
		setError(null)
		setFirstName(value)
	}
	const lastNameChange = (value) => {
		setError(null)
		setLastName(value)
	}
	const ddChange = (value) => {
		setError(null)
		setdd(value)
	}
	const mmChange = (value) => {
		setError(null)
		setmm(value)
	}
	const yyyyChange = (value) => {
		setError(null)
		setyyyy(value)
	}
	const address1Change = (value) => {
		setError(null)
		setAddress1(value)
	}
	const address2Change = (value) => {
		setError(null)
		setAddress2(value)
	}
	const cityChange = (value) => {
		setError(null)
		setCity(value)
	}
	const countyChange = (value) => {
		setError(null)
		setCounty(value)
	}
	const countryChange = (value) => {
		console.log(value)
		setError(null)
		setBrandServiceId(value.id)
	}
	const zipChange = (value) => {
		setError(null)
		setZip(value)
	}

	const termsChange = () => {
		setError(null)
		if (marketing === false) {
			setMarketing(true)
			setChecked("checked")
		} else {
			setMarketing(false)
			setChecked("")
		}
	}

	return (
		<div >
			<Container>
				<Row>
					<Form style={{ textAlign: 'left', paddingTop: '50px', paddingBottom: '20px' }} >
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>First Name</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => firstNameChange(e.target.value)} />
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Last Name</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => lastNameChange(e.target.value)} />
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Date of Birth</font></Form.Label>
							<Row>
								<div style={{ paddingLeft: '15px' }}><Form.Control type="text" placeholder="DD" className="mainLoginInput" style={{ border: 'none', backgroundColor: '#F8F5F5', width: '60px' }} onChange={(e) => ddChange(e.target.value)} /></div>
								<div style={{ paddingLeft: '20px' }}><Form.Control type="text" placeholder="MM" className="mainLoginInput" style={{ border: 'none', backgroundColor: '#F8F5F5', width: '60px' }} onChange={(e) => mmChange(e.target.value)} /></div>
								<div style={{ paddingLeft: '20px' }}><Form.Control type="text" placeholder="YYYY" className="mainLoginInput" style={{ border: 'none', backgroundColor: '#F8F5F5', width: '100px' }} onChange={(e) => yyyyChange(e.target.value)} /></div>
							</Row>
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Row style={{ paddingLeft: '5px' }}><Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Telephone</font></Form.Label></Form.Row>
							<Form.Row style={{ paddingLeft: '5px', paddingTop: '0px', marginTop: 0 }}><Form.Label style={{ paddingTop: '0px' }}><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '10px', fontWeight: '500' }}>This is the number from which you will be calling the service</font></Form.Label></Form.Row>
							{preferredCountriesArray.length > 0 && <PhoneInput countryCodeEditable={false} country={preferredCountriesArray[0].countryCode.toLowerCase()} preferredCountries={preferredCountriesArray} autoFormat={true} inputStyle={{ backgroundColor: '#F8F5F5', border: 'none' }} dropdownStyle={{ border: 'none' }} value={phone} onChange={(e, country) => { setPhone(e); setSelectedCountryCode(country.dialCode); }} />}
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>1st line of address</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => address1Change(e.target.value)} />
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>2nd line of address</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => address2Change(e.target.value)} />
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>State / County</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => countyChange(e.target.value)} />
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>City</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => cityChange(e.target.value)} />
						</Form.Group>
						<Form.Group controlId="exampleForm.ControlSelect1" style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Country</font></Form.Label>
							<Form.Control as="select" onChange={(e) => (setBrandServiceId(e.target.value), setSelectedCountryCode(preferredCountriesArray[e.target.value].countryCode))}>
								{preferredCountriesArray.map(function (item, i) {
									return <option value={i}>{item.countryName}</option>
								})}
							</Form.Control>
						</Form.Group>
						<Form.Group style={{ marginBottom: 0 }}>
							<Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>ZIP / Postal Code</font></Form.Label>
							<Form.Control type="text" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => zipChange(e.target.value)} />
						</Form.Group>
						{error && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}>{error}</font></Form.Label></Form.Group>}
						{error === null && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}> </font></Form.Label></Form.Group>}
						<Form.Group style={{ paddingBottom: '10px', paddingTop: '10px' }}>
							<Row fluid>
								<Col sm="auto" >
									<Form.Check style={{ border: 'none' }} onChange={(e) => termsChange()} checked={checked} />
								</Col>
								<Col onClick={(e) => termsChange()}><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px' }}>I consent to receiving marketing emails regarding the Psychic services advertised on this website.</font></Col>
							</Row>
						</Form.Group>
						<Button variant="primary" type="button" className="btn-primary" style={{ width: '100%', border: 'none', height: '35px' }} onClick={() => handleSubmit()}>
							{loading === false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Continue ></font>}
							{loading !== false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Loading</font>}
						</Button>
					</Form>
				</Row>
			</Container>
		</div>
	)
}
export default SignUpForm2;