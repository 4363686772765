import React, { useState } from 'react';
import '../components/SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Footer from '../components/Footer'
import ResetPasswordForm from '../components/ResetPasswordForm'
import logo from '../images/ddlogo.png';
import Header from '../components/Header'

function ResetPassword(props) {

  const history = useHistory();

  return (
    <div className="App">

    <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
    <Row style={{backgroundColor: '#C16854' }}><Header history={history}/></Row>
      <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%' }}>
        <Col xs={12}  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left', minHeight: 'calc(100% - 150px)' }}>

          <Container style={{ padding: '30px' }}>
            <ResetPasswordForm/>
          </Container>
        </Col>
        
        <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Footer />
        </Col>
      </Row>
    </Container>
  </div>
  )
}
export default ResetPassword;