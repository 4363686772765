import React, { useState } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../axios'

function ResetPasswordForm(props) {

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const history = useHistory();

  const handleSubmit = () => {

    if (validatePassword(password) !== true) {
      setError("Password should be 8-32 characters including at least: 1 Upper case letter, 1 Lower case letter and 1 Number with no spaces.")
      return
    }

    if (password != password2) {
      setError("Passwords do not match")
      return
    }

    let d = new URLSearchParams(window.location.search).get('d')

    setLoading(true)
    getAxiosInstance(false, window.location.hostname).post('/forgot/reset?d=' + d, { 'password': password, 'confirmPassword': password2 })
      .then((response) => {
        alert("Password changed successfully")
        setLoading(false);
      }).catch((err) => {
        //Handle error
        if (err.response) {
          if (err.response.data.Reason) {
            setError(err.response.data.Reason)
          } else {
            alert("Oops, something went wrong.")
          }
        }
        setLoading(false);
        console.log(err)
      })
  }

  function validatePassword(password) {
    var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,32}$/;
    return re.test(password);
  }
  const passwordChange = (value) => {
    setError(null)
    setPassword(value)
  }
  const password2Change = (value) => {
    setError(null)
    setPassword2(value)
  }

  return (
    <div >
      <Container>
        <Row >
          <Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', padding: 0 }}><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '35px', fontWeight: 700 }}>New password</font></Col>
        </Row>
        <Row>
          <Form style={{ textAlign: 'left', paddingTop: '50px', paddingBottom: '20px' }} >
            <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>New Password</font></Form.Label>
              <Form.Control type="password" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => passwordChange(e.target.value)} />
            </Form.Group>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Confirm New Password</font></Form.Label>
              <Form.Control type="password" style={{ border: 'none', backgroundColor: '#F8F5F5' }} onChange={(e) => password2Change(e.target.value)} />
            </Form.Group>
            {error && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}>{error}</font></Form.Label></Form.Group>}
            {error === null && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}> </font></Form.Label></Form.Group>}
            {loading === true && <Button variant="primary" type="button" style={{ width: '100%', backgroundColor: '#D28A79', border: 'none', height: '35px' }} ><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>loading</font></Button>}
            {loading !== true && <Button variant="primary" type="button" style={{ width: '100%', backgroundColor: '#D28A79', border: 'none', height: '35px' }} onClick={() => handleSubmit()}><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Save New Password ></font></Button>}
          </Form>
        </Row>
      </Container>
    </div>
  )
}
export default ResetPasswordForm;