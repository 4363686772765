import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../App.css';
import { useRecoilState } from 'recoil';
import { minutesBalance, dialingNumber } from '../Atoms';
import Refresh from '../images/refresh.png';
import Phone from '../images/phoneicon.png'
import { getAxiosInstance } from '../axios'

function FloatingMenu(props) {

  const [minutes, setMinutes] = useRecoilState(minutesBalance);
  const [dialingNo, setDialingNo] = useRecoilState(dialingNumber);

  const getCreditBalance = () => {
    setMinutes("-")
    getAxiosInstance(false, window.location.hostname).get('/credits')
      .then((response) => {
        setMinutes(response.data)
      }).catch((err) => {
        alert("Oops, something went wrong.")
      })
  }
  return (
    <Container fluid className="btn-primary" style={{ width: '100%', padding: 0 }}>
      <Row className="d-flex d-sm-none" style={{ height: '60px', width: '100%' }} ><Col xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', padding: 0 }} onClick={() => getCreditBalance()}><div className="floating-menu-font"><font style={{ fontFamily: 'Poppins', color: '#ffffff', fontWeight: '400' }}>{minutes} Mins</font></div> <div style={{ paddingLeft: '5px' }}><img src={Refresh} className="floating-menu-icons" /></div></Col><Col xs={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, width: '100%' }}><a href={"tel:" + dialingNo}>
        <Button style={{ display: 'flex', backgroundColor: '#84D631', border: 'none', width: '100%', alignItems: 'center', justifyContent: 'center' }}><div style={{ display: 'flex', paddingRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={Phone} className="floating-menu-icons" /></div><div className="floating-menu-font"><font style={{ fontFamily: 'Poppins', color: 'white', fontWeight: '400' }}>{dialingNo}</font></div></Button>
      </a></Col><Col xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', padding: 0 }} onClick={() => props.buy()}><div className="floating-menu-font" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Buy Mins ></div></Col></Row>
    </Container>
  )
}
export default FloatingMenu
