import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';


function ThankyouModal(props) {

    const handleClose = () => {
        props.close()
        props.history.push("/account")
    }

    return (
        <Container style={{ backgroundColor: '#F8F5F5' }}>
            <Row style={{ paddingTop: '30px', paddingRight: '20px' }} ><Col xs={12} onClick={handleClose}><div style={{ textAlign: 'right', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#464646', fontWeight: '700' }}>Close x</font></div></Col></Row>
            <Row style={{ paddingTop: '30px' }} ><Col xs={12}><div style={{ textAlign: 'center', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '45px', color: '#464646', fontWeight: '700' }}>Thank You!</font></div></Col></Row>
            <Row style={{ paddingTop: '30px', paddingBottom: '50px' }} ><Col xs={12}><div style={{ textAlign: 'center', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>Thank you! Your payment was successful and we have updated your minutes.</font></div></Col></Row>
        </Container>
    )
}
export default ThankyouModal;