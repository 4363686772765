import React, { useEffect } from 'react';
import logo from '../images/ddlogo.png';
import '../App.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import SignIn from '../components/SignIn'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useHistory } from "react-router-dom";

function Login() {

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">

      <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <Row style={{ backgroundColor: '#C16854' }}><Header history={history} /></Row>
        <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%' }}>
          <Col xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left', minHeight: 'calc(100% - 150px)' }}>
            <Container style={{ padding: 0 }}>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Col xs={11} sm={10} md={8} lg={6} xl={5} >
                <SignIn />
              </Col>
              </Row>
            </Container>
          </Col>
          <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Login;
