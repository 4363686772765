import React, { useEffect } from 'react';
import './App.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import SignUpForm1 from './components/SignUpForm1'
import { useHistory } from "react-router-dom";
import Footer from './components/Footer'
import Header from './components/Header'
import { getAxiosInstance, getAxiosInstanceLogin } from '../src/axios'

function App() {

  const history = useHistory();

  useEffect(() => {
    console.log(window.location.hostname)
    getAxiosInstanceLogin(false, window.location.hostname).get('/authed')
      .then((response) => {
        console.log(response)
        history.push('/account')
      }).catch((err) => {
        //Handle error        
        console.log(err)
      })
  }, []);


  return (

    <div>
      <div className="App">

        <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }} className="Background">
          <Row style={{ backgroundColor: '#C16854' }}><Header history={history} showLogin={true} /></Row>
          <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%', backgroundColor: '#F8F5F5' }}>
            <Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left', minHeight: 'calc(100% - 150px)' }}>

              <Container fluid style={{ padding: 0, height: '100%', width: '100%' }}>
                <Row style={{ height: '100%' }}>


                  <div className="d-none d-md-flex" style={{ width: '100%' }}>
                    <Col md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>

                      <Container fluid style={{ paddingTop: '100px', paddingBottom: '100px', width: '100%' }}>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Col xs={12} sm={12} md={12} lg={8} xl={6} className="Background"><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Let’s create your account!</font></Col></Row>
                        <Row style={{ paddingTop: '30px', display: 'flex', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>Speak to your favourite Psychic readers over the telephone when it is convenient for you, by using our online Account service to buy your minutes. Your conversations and Psychic readings are 100% confidential.</font></Col></Row>
                      </Container>

                    </Col><Col xs={12} md={6} style={{ backgroundColor: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                      <Container fluid style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={10} xl={8}>
                          <Row ><Col xs={12} style={{ width: '100%', paddingTop: '50px' }} >

                            <Container fluid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Col xs={11} sm={10} md={10} lg={10} xl={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <SignUpForm1 />
                              </Col>
                              </Row>
                            </Container>
                          </Col></Row>
                        </Col></Row>
                      </Container>

                    </Col>

                  </div>

                  <div className="d-flex d-md-none" style={{ width: '100%' }}>
                    <Col xs={12} style={{ backgroundColor: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} >
                          <Row ><Col xs={12} style={{ width: '100%', padding: 0 }} >
                            <Container style={{ paddingTop: '10px' }}>
                              <Row style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}><Col xs={12} sm={12} md={12} lg={8} xl={6} className="Background" style={{ lineHeight: '25px' }}><font style={{ fontFamily: 'Poppins', fontSize: '25px', color: '#464646', fontWeight: '700' }}>Let’s create your account!</font></Col></Row>
                              <Row style={{ paddingTop: '20px', display: 'flex', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={8} xl={6} style={{ lineHeight: '15px' }}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '500' }}>Speak to your favourite Psychic readers over the telephone when it is convenient for you, by using our online Account service to buy your minutes. Your conversations and Psychic readings are 100% confidential.</font></Col></Row>
                            </Container>
                            <Container style={{ padding: 0 }}>
                              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}><Col xs={11} sm={10} md={10} lg={10} xl={10} >
                                <SignUpForm1 />
                              </Col>
                              </Row>
                            </Container>
                          </Col></Row>
                        </Col></Row>
                      </Container>
                    </Col>
                  </div>
                </Row>
              </Container>
            </Col>
            <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Footer showLogOut={false} />
            </Col>
          </Row>
        </Container>

      </div>
    </div>
  );
}

export default App;
