// @noflow

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import getAxiosInstance from '../axios'
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import '../App.css';
import lock from '../images/lock.png'

const CheckoutForm = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [paymentUID, setPaymentUID] = useState(null)
  const stripe = useStripe();
  const elements = useElements();



  const handleSubmit = async ev => {
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(props.bundle.stripePaymentIntentSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      console.log("Success!")
      setProcessing(false);
      setSucceeded(true);
      props.success()
    }
  };

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : null);
  };

  const cardStyle = {
    style: {
      base: {
        color: "#464646",
        fontFamily: 'Poppins, sans-serif',
        fontSize: "15px",
        fontWeight: '400',
        backgroundColor: 'white',
        "::placeholder": {
          color: 'rgba(70, 70, 70, 0.7)',
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  return (
    <Container style={{ paddingBottom: '10px' }}>
      <Row><Col style={{ backgroundColor: 'white', paddingTop: '10px', paddingBottom: '10px' }}><CardElement id="card-element" options={cardStyle} onChange={handleChange} /></Col></Row>
      <Row style={{ paddingTop: '30px' }}><Col style={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Button disabled={processing || disabled || succeeded} id="submit" style={{ width: '100%', border: 'none' }} className="btn-primary" onClick={() => handleSubmit()}><img src={lock} style={{ height: '20px', paddingRight: '5px' }} /><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: 'white', fontWeight: '700' }}>Confirm Payment ></font></Button></Col></Row>
      {error && (<div className="card-error" role="alert">{error}</div>)}
    </Container>
  );
};
export default CheckoutForm;