import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';


function FreeMinutesSuccess(props) {

    const handleClose = () => {
        props.close()
    }

    return (
        <Container style={{ backgroundColor: '#F8F5F5' }}>
            <Row style={{ paddingTop: '30px' }} ><Col xs={11} onClick={handleClose}><div style={{ textAlign: 'right', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#464646', fontWeight: '700' }}>Close x</font></div></Col></Row>
            <Row style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Success!</font></Col></Row>
            <Row style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#464646', fontWeight: '700' }}>{props.addedMins} FREE minutes have been added to your account </font></Col></Row>
            <Row style={{ paddingTop: '30px', paddingBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><Button variant="primary" type="button" style={{ width: '100%', backgroundColor: '#D28A79', border: 'none', height: '35px' }} onClick={handleClose}><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Close ></font></Button></Col></Row>
        </Container>
    )
}
export default FreeMinutesSuccess;