import React, { useEffect, useState } from 'react';
import '../App.css';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Footer from '../components/Footer'
import { getAxiosInstance } from '../axios'
import Header from '../components/Header'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckOutForm'
import PayPalButton from '../components/PayPalButton'
import amex from '../images/amex.png'
import visa from '../images/visa.png'
import mastercard from '../images/mastercard.png'
import ThankyouModal from '../components/Modals/ThankyouModal';
import { useRecoilState } from 'recoil';
import { BundleAtom } from '../Atoms'
import { useHistory } from "react-router-dom";

const stripePromise = loadStripe('pk_live_6egOIamIybTZWS0aOv39dEqu');

function Purchase() {
  const history = useHistory();
  const [bundle, setBundle] = useState(null)
  const [coupon, setCoupon] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedBundle, setSelectedBundle] = useRecoilState(BundleAtom);

  const getBundle = () => {
    console.log("updating bundle")
    setBundle(null)

    getAxiosInstance(false, window.location.hostname).post('/bundles/' + localStorage.getItem('selectedBundle') + '/buy')
      .then((response) => {
        setBundle(response.data)
        console.log(response)
      }).catch((err) => {
        //Handle erro
        console.log(err)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getAxiosInstance(false, window.location.hostname).get('/service')
      .then((response) => {
        console.log(response)
        var script = document.createElement('script');
        script.src = "https://www.paypal.com/sdk/js?client-id=AaVoFkYRtV2qd5n9iWT3KWl98CSt-6fU0sPW0acZn-bUnm5dEYW8Axx7ZiNCtfIfjvRlR-CPH8ZIHlwS&disable-funding=card&intent=authorize&currency=" + response.data.currencyCode;
        document.head.appendChild(script);
      }).catch((err) => {
        //Handle error        
        console.log(err)
      })
    getBundle()
  }, []);

  const ApplyCoupon = (e) => {
    e.preventDefault()
    console.log(coupon)
    if (coupon !== "") {
      getAxiosInstance(false, window.location.hostname).post('/bundles/purchases/' + bundle.purchaseUID + '/apply-coupon?coupon=' + coupon)
        .then((response) => {
          setBundle(response.data)
          console.log(response)
        }).catch((err) => {
          //Handle error
          if (err.response) {
            if (err.response.data.Reason) {
              alert(err.response.data.Reason)
            } else {
              alert("Oops, something went wrong.")
            }
          }
        })
    }
  }

  return (
    <div className="App">

      <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <Row style={{ backgroundColor: '#C16854' }}><Header history={history} /></Row>
        <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%' }}>
          <Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'left', minHeight: 'calc(100% - 150px)' }}>

            <Container fluid style={{ padding: 0, height: '100%', width: '100%' }}>
              <Row style={{ height: '100%' }}>

                <Col xs={12} md={6} style={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '30px', paddingTop: '30px' }}><Col xs={12} md={11} lg={8} xl={6}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Order Summary</font></Col></Row>

                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} md={11} lg={8} xl={6}>

                      <Container fluid>

                        <Row><Col xs={6}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>Bundle</font></Col><Col xs={6}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>Price</font></Col></Row>
                        <Row style={{ paddingBottom: '30px' }}><Col xs={6}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '400' }}>{bundle && bundle.product}</font></Col><Col xs={6}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>{bundle && bundle.originalPriceString}</font></Col></Row>
                        <Row><Col xs={12}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>Mins Purchased</font></Col></Row>
                        <Row style={{ paddingBottom: '30px' }}><Col xs={12}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '400' }}>{bundle && bundle.finalCredits} mins</font></Col></Row>

                        {bundle && bundle.discountString && <div><Row><Col xs={12}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>Discounts</font></Col></Row>
                          <Row style={{ paddingBottom: '30px' }}><Col xs={12}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '400' }}>{bundle.discountString}</font></Col></Row></div>}

                        <Row style={{ paddingBottom: '50px' }}><Col xs={12}><font style={{ fontFamily: 'Poppins', fontSize: '15px', fontWeight: '700' }} className="primary-color-text">Total {bundle && bundle.finalPriceString}</font></Col></Row>

                      </Container>
                    </Col>
                    </Row>

                  </Container>

                </Col><Col xs={12} md={6} style={{ backgroundColor: '#F8F5F5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                  <Container style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={12} sm={12} md={12} lg={10} xl={8}>
                      <Row ><Col xs={12} style={{ width: '100%', paddingTop: '50px' }} >


                        <Form.Row>
                          <Col xs={12}><Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Apply Discount Coupon</font></Form.Label></Col>

                          <Col xs={8}><Form.Control type="text" style={{ border: 'none' }} placeholder="Enter your unique code" onChange={(e) => setCoupon(e.target.value)} /></Col><Col xs={4}><Button style={{ border: 'none' }} className="btn-primary" onClick={(e) => ApplyCoupon(e)}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: 'white', fontWeight: '400' }}>Apply ></font></Button></Col></Form.Row>

                      </Col></Row>
                      <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px', paddingTop: '30px' }}><Col xs={12} md={12} lg={12} xl={12}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Payment Details</font></Col></Row>

                      <Row style={{ paddingBottom: '30px' }}><Col xs="auto" style={{ paddingRight: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={amex} style={{ height: '30px' }} /></Col><Col xs="auto" style={{ padding: '5px' }}><img src={visa} style={{ height: '30px' }} /></Col><Col xs="auto" style={{ padding: '5px' }}><img src={mastercard} style={{ height: '30px' }} /></Col></Row>
                      <Row><Col>
                        <Elements stripe={stripePromise}>
                          <CheckoutForm bundle={bundle} success={handleShow} history={history} />
                        </Elements>
                      </Col></Row>

                      <Row style={{ paddingBottom: '30px' }}><Col>
                        {bundle && <PayPalButton purchaseID={bundle.purchaseUID} success={handleShow} reloadBundle={() => getBundle()} history={history} />}
                      </Col></Row>


                    </Col></Row>
                  </Container>

                </Col></Row>

            </Container>

          </Col>
          <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Footer />
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <ThankyouModal close={handleClose} history={history} />
      </Modal>
    </div>
  );
}

export default Purchase;
