import React, { useState } from "react";
import { getAxiosInstance } from '../axios'
import { Modal, Spinner, Container, Row, Col } from 'react-bootstrap';

export default function PayPalButton(props) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);
  const paypalRef = React.useRef();
  const [orderID, setOrderID] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getSecret() {

    return new Promise((resolve, reject) => {
      getAxiosInstance(false, window.location.hostname).post('/purchase/' + props.purchaseID + '/payment-intent?psp=PAYPAL')
        .then((response) => {
          console.log(response)
          resolve(response.data.payPalOrderId)
        }).catch((err) => {
          //Handle erro
          reject(err)
          console.log(err)
        })
    })

  }

  // To show PayPal buttons once the component loads
  React.useEffect(() => {
    window.paypal
      .Buttons({
        style: { "height": 35 },
        createOrder: async (data, actions) => {
          setShow(true)
          const payPalOrderId = await getSecret()
          console.log("SET ORDERID " + payPalOrderId)
          setOrderID(payPalOrderId)
          return payPalOrderId
        },

        onApprove: (data, actions) => {
          console.log(data);

          // Authorize the transaction
          return actions.order.authorize().then(function (authorization) {

            // Get the authorization id
            var authorizationID = authorization.purchase_units[0]
              .payments.authorizations[0].id
            console.log(authorization);
            // Call your server to validate and capture the transaction
            getAxiosInstance(false, window.location.hostname).post('/purchase/' + props.purchaseID + '/capture', { 'orderId': data.orderID, 'authorizationId': authorizationID })
              .then((response) => {
                console.log(response)
                setShow(false)
                props.success()
              }).catch((err) => {
                //Handle erro
                alert(err)
                console.log(err)
              })
          })
        },
        onError: (err) => {
          setError(err)
          console.error(err);
        },
        onCancel: function (data) {
          // Show a cancel page, or return to cart
          props.reloadBundle()
        }
      })
      .render(paypalRef.current);
  }, []);

  // If the payment has been made
  if (paid) {
    return <div>Payment successful.!</div>;
  }

  // If any error occurs
  if (error) {
    return <div>Error Occurred in processing payment.! Please try again.</div>;
  }

  // Default Render
  return (
    <div>
      {error && <div><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '400' }}>{error}</font></div>}
      <div ref={paypalRef} />
      <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Container>
          <Row style={{ paddingTop: '50px', paddingBottom: '10px' }}><Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner animation="border" className="spinner" /></Col></Row>
          <Row style={{ paddingBottom: '50px' }}><Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>One Moment Please</font></Col></Row>
        </Container>
      </Modal>
    </div>
  );
}