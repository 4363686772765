import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { getAxiosInstance } from '../axios'

function Header(props) {

  const [bannerText, setBannerText] = useState("")

  useEffect(() => {
    getAxiosInstance(false, window.location.hostname).get('/banner')
      .then((response) => {
        setBannerText(response.data.banner)
      }).catch((err) => {
        console.log(err)
      })
  }, []);

  return (
    <Container fluid style={{ height: '100%', width: '100%' }}>
      <Row style={{ height: '60px' }} className="header-gradient"><Col xs={12} md={2} style={{ position: 'relative', zIndex: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => props.history.push('/account')}><div className="logo-image" /></Col>{props.showLogin !== undefined && <div style={{ width: '100%', height: '60px', position: 'absolute', display: 'flex', zIndex: 1, alignItems: 'center', justifyContent: 'flex-end', cursor: "pointer", paddingRight: '2vw' }} onClick={() => props.history.push('/login')}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Button style={{ backgroundColor: '#84D631', border: 'none', width: '100px' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'white', fontWeight: '700' }}>Login ></font></Button></div></div>}</Row>
      {bannerText !== "" && bannerText !== undefined && bannerText !== null && <Row className="banner"><Col xs={12} style={{ padding: '5px' }}><font style={{ fontFamily: 'Poppins', color: '#ffffff', fontWeight: '400', fontSize: '15px' }}>{bannerText}</font></Col></Row>}
    </Container>
  )
}
export default Header;