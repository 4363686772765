import React, { useState, useEffect } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {getAxiosInstanceLogin} from '../axios'



function SignIn(props) {

  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false)
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submit = () => {

if(email === "" || password === ""){
  setError("Email / Password cannot be empty")
  return
}

setLoading(true);
getAxiosInstanceLogin(false,window.location.hostname).post('/login', {"email": email, "password": password})
.then((response)=>{
  history.push("/account")
}).catch((err)=>{
 //Handle error
 setLoading(false);
 if (err.response) {
 if(err.response.data.Reason){
 setError(err.response.data.Reason)
 }else{
   alert("Oops, something went wrong.")
 }
}
})
  }

const onChangeEmail = (value) => {
  setError(null)
  setEmail(value)
}

const onChangePassword = (value) => {
  setError(null)
  setPassword(value)
}

  return (

    <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
      <Row><Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Sign in</font></Col></Row>
      <Row><Col xs={12}>
        <Form style={{ textAlign: 'left', paddingTop: '20px', paddingBottom: '20px' }} >
          <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
          <Form.Group controlId="formGroupEmail" style={{ marginBottom: 0 }} onChange={(e) => onChangeEmail(e.target.value)}>
            <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Email</font></Form.Label>
            <Form.Control type="email" style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
          </Form.Group>
          <Form.Group controlId="formGroupPassword" style={{ marginBottom: 0, paddingBottom: '30px' }} onChange={(e) => onChangePassword(e.target.value)}>
            <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Password</font></Form.Label>
            <Form.Control type="password" style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
          </Form.Group>
          {error && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}>{error}</font></Form.Label></Form.Group>}
          <Button variant="primary" type="button" style={{ width: '100%', border: 'none', height: '35px' }}  className="btn-primary" onClick={() => submit()}>
            {loading === false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Sign In ></font>}
            {loading !== false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Loading</font>}
          </Button>
        </Form>
      </Col>
      </Row>
      <Row style={{ display: 'flex', alignItems: 'left', justifyContent: 'center', textAlign: 'left' }} onClick={() => history.push("/forgot")}><Col xs={11} style={{ padding: 0 }}><font style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 700, textDecoration: 'underline' }} className="primary-color-text">Forgotten Password ></font></Col></Row>
    </Container>
  )
}
export default SignIn;