import React, { useState } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../axios'

function SignUpForm1(props) {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [error, setError] = useState(null)
  const [checked, setChecked] = useState("")
  const history = useHistory();

  const handleSubmit = (e) => {

    e.preventDefault()

    if (validateEmail(email) != true) {
      setError("Email is invalid.")
      return
    }

    if (validatePassword(password) !== true) {
      setError("Password should be 8-32 characters including at least: 1 Upper case letter, 1 Lower case letter and 1 Number with no spaces.")
      return
    }

    if (password != password2) {
      setError("Passwords do not match")
      return
    }

    if (acceptedTerms != true) {
      setError("You must accept terms and conditions.")
      return
    }

    getAxiosInstance(false, window.location.hostname).get('/signup/exists?email=' + email)
      .then((response) => {
        if (response.data === true) {
          setError("Account exists. please use log in form.")
        } else {
          history.push('/create', { "email": email, password: password, "password2": password2, "acceptedTerms": acceptedTerms })
        }
      }).catch((err) => {
        alert("Server Error")
      })
  }

  function validateEmail(email) {
    var re = /^(\S+@\S+){1,254}\..{1,10}$/;
    return re.test(email);
  }

  function validatePassword(password) {
    var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,32}$/;
    return re.test(password);
  }

  const termsChange = (value) => {
    setError(null)
    if (acceptedTerms === false) {
      setAcceptedTerms(true)
      setChecked("checked")
    } else {
      setAcceptedTerms(false)
      setChecked("")
    }
  }
  const emailChange = (value) => {
    setError(null)
    setEmail(value)
  }
  const passwordChange = (value) => {
    setError(null)
    setPassword(value)
  }
  const password2Change = (value) => {
    setError(null)
    setPassword2(value)
  }

  return (
    <div >
      <Container>
        <Row>
          <Form style={{ textAlign: 'left', paddingTop: '15px', paddingBottom: '20px' }} >
            <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
            <Form.Group controlId="formGroupEmail" style={{ marginBottom: 0 }}>
              <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Email</font></Form.Label>
              <Form.Control type="email" style={{ border: 'none' }} name="email" type="text" value={email} autoComplete="off" onChange={(e) => emailChange(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formGroupPassword" style={{ marginBottom: 0 }}>
              <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Password</font></Form.Label>
              <Form.Control type="password" style={{ border: 'none' }} name="password" autoComplete="off" onChange={(e) => passwordChange(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formGroupPassword" style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Confirm Password</font></Form.Label>
              <Form.Control type="password" style={{ border: 'none', paddingBottom: 0 }} name="confirm" autoComplete="new-password" onChange={(e) => password2Change(e.target.value)} />
            </Form.Group>

            {error !== null && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}>{error}</font></Form.Label></Form.Group>}


            <Form.Group style={{ marginBottom: 0, paddingTop: '15px', paddingBottom: '20px' }}>
              <Row fluid style={{ padding: 0 }}>


                <Col onClick={() => termsChange()} style={{ lineHeight: '12px' }}><Form.Check style={{ border: 'none', display: 'inline-block' }} onChange={(e) => termsChange()} checked={checked} /><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px' }}>I am at least 18 years old and have read and agree to the <a href="https://www.moonbar.com.au/terms-conditions" target="_blank">Terms & Conditions</a> and <a href="https://www.moonbar.com.au/privacy-policy" target="_blank">Privacy Policy.</a></font></Col>
              </Row>
            </Form.Group>

            <Button variant="primary" type="submit" style={{ width: '100%', border: 'none', height: '35px' }} className="btn-primary" onClick={(e) => handleSubmit(e)}>
              <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Create my account ></font>
            </Button>
          </Form>
        </Row>

        <Row style={{ paddingBottom: '60px', display: 'flex', alignItems: 'left', justifyContent: 'center', textAlign: 'left' }} onClick={() => history.push('/login')}><Col xs={12} style={{ padding: 0 }}><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '14px', fontWeight: 700 }}>Already got an account? </font><font style={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 700, textDecoration: 'underline', cursor: 'pointer' }} className="primary-color-text">Click here to log in</font></Col></Row>
      </Container>
    </div>

  )
}
export default SignUpForm1;