import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../../axios'


function FreeMinutesModal(props) {

  const [addedMins, setAddedMins] = useState("")
  const [freeCode, setFreeCode] = useState("")
  const [loadingCode, setLoadingCode] = useState(false)
  const [show, setShow] = useState(false);
  const [error, setError] = useState("")

  const handleClose = () => {
    props.close()
  }

  const change = (value) => {
    setError("")
    setFreeCode(value)
  }

  const freeMinutes = () => {
    setAddedMins("")
    if (freeCode !== "") {
      setLoadingCode(true)
      getAxiosInstance(false, window.location.hostname).post('/redeem/freeminutes', { "code": freeCode })
        .then((response) => {
          setAddedMins(response.data)
          setShow(true)
          setLoadingCode(false)
        }).catch((err) => {
          setLoadingCode(false);
          if (err.response.data.Reason !== undefined) {
            setError(err.response.data.Reason)
          } else {
            alert("Oops, something went wrong.")
          }
        })
    }
  }

  return (
    <Container style={{ backgroundColor: '#F8F5F5' }}>
      <Row style={{ paddingTop: '30px' }} ><Col xs={11} onClick={handleClose}><div style={{ textAlign: 'right', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#464646', fontWeight: '700' }}>Close x</font></div></Col></Row>
      <Row style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Redeem free minutes</font></Col></Row>
      <Row style={{ paddingTop: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: '#464646', fontWeight: '700' }}>Enter your unique code </font></Col></Row>
      <Row style={{ paddingTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11} ><Form.Group style={{ marginBottom: 0 }}><Form.Control type="text" style={{ border: 'none', backgroundColor: 'white' }} onChange={(e) => change(e.target.value)} /></Form.Group></Col></Row>
      {error !== "" && <Row style={{ paddingTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'red', fontWeight: '700' }}>{error}</font></Col></Row>}
      {addedMins !== "" && <Row style={{ paddingTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'green', fontWeight: '700' }}>{addedMins} FREE minutes have been added to your account.</font></Col></Row>}

      <Row style={{ paddingTop: '30px', paddingBottom: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11}><Button variant="primary" disabled={loadingCode} type="button" style={{ width: '100%', border: 'none', height: '35px' }} className="btn-primary" onClick={() => freeMinutes()}><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Submit ></font></Button></Col></Row>


    </Container>
  )
}
export default FreeMinutesModal;