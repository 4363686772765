import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import StarImage from '../images/star.png'


function Stars(props) {

    return (
        <Container fluid style={{ padding: 0 }}>
            <Row style={{ padding: 0 }}>
                <Col style={{ display: 'inline-block' }}>{props.Rating > 0 && <div style={{ display: 'inline-block' }}>⭐️</div>}{props.Rating > 1 && <div style={{ display: 'inline-block' }}>⭐️</div>}{props.Rating > 2 && <div style={{ display: 'inline-block' }}>⭐️</div>}{props.Rating > 3 && <div style={{ display: 'inline-block' }}>⭐️</div>}{props.Rating > 4 && <div style={{ display: 'inline-block' }}>⭐️</div>}</Col>
            </Row>
        </Container>
    )
}
export default Stars;