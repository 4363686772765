import React, { useEffect, useState} from 'react';
import '../App.css';
import { Container, Row, Col, Spinner, Button, Form, Modal } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import Footer from '../components/Footer'
import {getAxiosInstance} from '../axios'
import FreeMinutesSuccess from '../components/FreeMinutesSuccess'
import {BundleAtom} from '../Atoms'
import {useRecoilState} from 'recoil';
import Header from '../components/Header'
import FloatingMenu from '../components/FloatingMenu'


function Bundles() {

  const history = useHistory();
  const [loading, setLoading] = useState(true)
  const [bundles, setBundles] = useState(null)
  const [show, setShow] = useState(false);
  const [addedMins, setAddedMins] = useState("")
  const [freeCode, setFreeCode] = useState("")
  const [loadingCode, setLoadingCode] = useState(false)
  const [selectedBundle, setSelectedBundle] = useRecoilState(BundleAtom);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const freeMinutes = () => {
    if (freeCode !== "") {
      setLoadingCode(true)
      getAxiosInstance(false,window.location.hostname).post('/redeem/freeminutes', { "code": freeCode })
        .then((response) => {
          setAddedMins(response.data)
          setShow(true)
          setLoadingCode(false)
        }).catch((err) => {
          setLoadingCode(false);
          if (err.response.data.Reason !== undefined) {
            alert(err.response.data.Reason)
          } else {
            alert("Oops, something went wrong.")
          }
        })
    }
  }

 
  useEffect(() => {
    window.scrollTo(0, 0)

    getAxiosInstance(false,window.location.hostname).get('/bundles')
      .then((response) => {
        setBundles(response.data.content)
       
        setLoading(false);
      }).catch((err) => {
        //Handle error
        setLoading(false);
        console.log(err)
      })
  }, []);


const onClickBundle = (bundle) => {
  console.log(bundle)
  setSelectedBundle(bundle)
  localStorage.setItem('selectedBundle', bundle);
  history.push("/purchase")
}


  const bundleRows = () => {
    return <div>
      <Container style={{ paddingTop: '30px' }}><Row style={{ justifyContent: 'center' }}>
        {bundles.map((bundle) =>
          <Col xs={6} md={4} lg={3} style={{ paddingBottom: '30px' }} onClick={() => onClickBundle(bundle.bundleId)}>
            <Container style={{ boxShadow: '-1px 3px 7px #00000029',cursor: "pointer" }}>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}><font style={{ fontFamily: 'Poppins', fontSize: '25px', color: '#464646', fontWeight: '700' }}>{bundle.credits}</font></Row>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '400' }}>Minutes</font></Row>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>{bundle.priceString}</font></Row>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '400' }}>{bundle.costPerMin} per minute</font></Row>
              <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="btn-primary"><div style={{ padding: '10px' }}><font style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#464646', fontWeight: '700', color: 'white' }}>Select Bundle ></font></div> </Row>
            </Container>
          </Col>
        )}
      </Row>
      </Container>
      </div>
  }

  return (
    <div className="App">

<div>
      <Container fluid style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <Row style={{backgroundColor: '#C16854' }}><Header history={history}/></Row>
        <Row style={{ height: 'calc(100% - 70px)', minHeight: '78%' }}>
          <Col xs={12} md={12} style={{ minHeight: 'calc(100% - 150px)' }}>

            <Container style={{ padding: 0, height: '100%' }}>


              {loading === true && <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><Col xs={12} ><Spinner animation="border" className="spinner"/></Col></Row>}
              {loading === false && <div style={{ paddingTop: '50px' }}>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><Col xs={11} md={12}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Choose a Bundle</font></Col></Row>

                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Col xs={12} >{bundles && bundleRows()}</Col></Row>

                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11} md={10} lg={8}><hr style={{ backgroundColor: '#464646', opacity: 0.13, height: '2px', border: 'none' }} /></Col></Row>

                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px', paddingTop: '20px' }}><Col xs={11} md={10} lg={8}><font style={{ fontFamily: 'Poppins', fontSize: '15px', color: '#464646', fontWeight: '700' }}>Redeem Free Account Minutes</font></Col></Row>

                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Col xs={11} md={4} lg={3} style={{ paddingBottom: '20px', paddingRight: '3px', paddingLeft: '3px' }}>

                  <Form>
                    <Form.Group style={{ marginBottom: 0 }}>
                      <Form.Control className="mainLoginInput" type="text" placeholder="Enter your unique code"  onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Enter your unique code"} value={freeCode} style={{ border: 'none', backgroundColor: '#F8F5F5', textAlign: 'center' }} onChange={(event) => setFreeCode(event.target.value)} />
                    </Form.Group>
                  </Form>
                </Col>
                  {loadingCode === false && <Col xs={11} md={4} lg={3} style={{ paddingBottom: '20px', paddingRight: '3px', paddingLeft: '3px' }}> <Button variant="primary" type="button" style={{ width: '100%',  border: 'none', height: '35px' }} className="btn-primary" onClick={() => freeMinutes()}><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Submit ></font></Button></Col>}
                  {loadingCode !== false && <Col xs={11} md={4} lg={3} style={{ paddingBottom: '20px', paddingRight: '3px', paddingLeft: '3px' }}> <Button variant="primary" type="button" style={{ width: '100%', border: 'none', height: '35px' }} className="btn-primary" ><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Loading</font></Button></Col>}
                </Row>

                <Row style={{ paddingTop: '20px', paddingBottom: '50px' }} onClick={() => history.push('/account')}><Col><font style={{ fontFamily: 'Poppins', fontSize: '14px', color: '#464646', fontWeight: '700', textDecoration: 'underline' }}>Skip this step</font></Col></Row>
              </div>}

            </Container>
          </Col>
          <Col x={12} style={{ backgroundColor: '#464646', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Footer />
          </Col>

        </Row>

      </Container>

      <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
        <FreeMinutesSuccess close={() => setShow(false)} addedMins={addedMins} />
      </Modal>

      </div>

      <div style={{position:'fixed', bottom:0, width:'100%', padding:0}}>
      <FloatingMenu/>
    </div>

    </div>
  );
}



export default Bundles;
