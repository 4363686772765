import React, { useState, useEffect } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import SafeCheckout from '../images/safecheckout.png'
import { getAxiosInstance } from '../axios'


function Footer(props) {

  const history = useHistory();
  const [termsURL, setTermsURL] = useState("")
  const [privacyURL, setPrivacyURL] = useState("")

  const logout = () => {

    getAxiosInstance(false, window.location.hostname).post('/logout')
      .then((response) => {
        history.push('/')
      }).catch((err) => {       
        console.log(err)
      })
  }

  useEffect(() => {
    var xhr1 = new XMLHttpRequest()
    xhr1.addEventListener('load', () => {
      setTermsURL(xhr1.responseText)
    })
    xhr1.open('GET', '/termsserver.php')
    xhr1.send()

    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      setPrivacyURL(xhr.responseText)
    })
    xhr.open('GET', '/privacyserver.php')
    xhr.send()
  }, []);

  return (
    <Container fluid>
      <Row style={{ paddingBottom: '50px', paddingTop: '50px' }}>
        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={2}><div className="logo-image" /></Col>
        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={{ span: 2, offset: 4 }} onClick={() => window.open(termsURL)}><font style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'underline', cursor: "pointer" }}>Terms & Conditions</font></Col>
        {props.showLogOut === undefined && <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={1} onClick={() => window.open(privacyURL)}><font style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'underline', cursor: "pointer" }}>Privacy Policy</font></Col> || <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={2} onClick={() => window.open(privacyURL, '_blank')}><font style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'underline', cursor: "pointer" }}>Privacy Policy</font></Col>}
        {props.showLogOut === undefined && <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={2} onClick={() => logout()}><font style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'underline', cursor: "pointer" }}>Log Out</font></Col>}
        {props.showLogOut === undefined && <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={1}><img src={SafeCheckout} style={{ height: '36px' }} /></Col> || <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }} xs={12} md={2}><img src={SafeCheckout} style={{ height: '36px' }} /></Col>}
      </Row>
    </Container>
  )
}
export default Footer;