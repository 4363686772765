import React, { useState } from 'react';
import './SignUpForm1.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../axios'


function ForgotPasswordForm(props) {

  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory();

  const handleSubmit = () => {
    if (validateEmail(email) != true) {
      setError("Email is invalid.")
      return
    }

    setLoading(true)
    getAxiosInstance(false, window.location.hostname).post('/forgot/send-reset', { 'email': email })
      .then((response) => {
        alert("Sent - Please check your email for further instructions.")
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
      })
  }

  const emailChange = (value) => {
    setError(null)
    setEmail(value)
  }

  function validateEmail(email) {
    var re = /^(\S+@\S+){1,254}\..{1,10}$/;
    return re.test(email);
  }

  return (


    <Container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
      <Row><Col xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}><font style={{ fontFamily: 'Poppins', fontSize: '35px', color: '#464646', fontWeight: '700' }}>Forgot Password</font></Col></Row>
      <Row><Col xs={12}>
        <Form style={{ textAlign: 'left', paddingTop: '20px', paddingBottom: '20px' }} >
          <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
          <Form.Group controlId="formGroupEmail" style={{ marginBottom: 0, paddingBottom: '30px' }} onChange={(e) => emailChange(e.target.value)}>
            <Form.Label><font style={{ fontFamily: 'Poppins', color: '#464646', fontSize: '12px', fontWeight: '700' }}>Email</font></Form.Label>
            <Form.Control type="email" style={{ border: 'none', backgroundColor: '#F8F5F5' }} />
          </Form.Group>
          {error && <Form.Group ><Form.Label><font style={{ fontFamily: 'Poppins', color: '#DD4444', fontSize: '12px', fontWeight: '700' }}>{error}</font></Form.Label></Form.Group>}
          <Button variant="primary" type="button" style={{ width: '100%', border: 'none', height: '35px' }} className="btn-primary" onClick={() => handleSubmit()}>
            {loading === false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Send reset link ></font>}
            {loading !== false && <font style={{ fontFamily: 'Poppins', fontSize: '14px', color: 'white' }}>Loading</font>}
          </Button>
        </Form>
      </Col>
      </Row>
    </Container>
  )
}
export default ForgotPasswordForm;