import React, { useState, useEffect } from 'react';
import Refresh from '../images/refresh.png';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { getAxiosInstance } from '../axios'
import { minutesBalance, dialingNumber } from '../Atoms';
import { useRecoilState } from 'recoil';

function HeaderDetails(props) {

    const history = useHistory();
    const [creditBalance, setCreditBalance] = useState(null)
    const [minutes, setMinutes] = useRecoilState(minutesBalance);
    const [bannerText, setBannerText] = useState("")


    const getBanner = () => {
        getAxiosInstance(false, window.location.hostname).get('/banner')
            .then((response) => {
                setBannerText(response.data.banner)
            }).catch((err) => {
                //Handle error   
                console.log(err)
            })
    }
    useEffect(() => {
        getCreditBalance()
        getBanner()
    }, []);

    const getCreditBalance = () => {
        setCreditBalance(null)
        getAxiosInstance(false, window.location.hostname).get('/credits')
            .then((response) => {
                setCreditBalance(response.data)
                setMinutes(response.data)
            }).catch((err) => {
                alert("Oops, something went wrong.")
            })
    }

    return (
        <Container fluid style={{ height: '100%', width: '100%' }}>
            <Row style={{ height: '70px' }} className="header-gradient"><Col xs={12} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }} onClick={() => props.history.push("/account")}><div className="logo-image" /></Col>
                <Col className="d-none d-md-block" style={{ padding: 0 }}>
                    <Container fluid  >
                        <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '70px' }}>
                            <Col xs={4} md={4} lg={3} xl={3} onClick={() => props.redeem()} ><Button style={{ backgroundColor: '#84D631', border: 'none', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'white', fontWeight: '700' }}>Redeem Free Minutes ></font></Button></Col>
                            <Col xs={4} md={4} lg={3} xl={3} onClick={() => history.push('/bundles')}><Button style={{ backgroundColor: '#84D631', border: 'none', width: '100%' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'white', fontWeight: '700' }}>Purchase Minutes ></font></Button></Col>
                            <Col xs={4} md={3} lg={3} xl={2}>
                                <Container onClick={() => getCreditBalance()}>
                                    <Row><div style={{ display: 'inline' }}><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'white', fontWeight: '400' }}>Current Balance</font></div><div><img src={Refresh} style={{ height: '10px', paddingLeft: '5px', cursor: "pointer" }} /></div></Row>
                                    <Row><font style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'white', fontWeight: '700' }}>{minutes} Minutes</font></Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col></Row>
            {bannerText !== "" && bannerText !== undefined && bannerText !== null && <Row className="banner"><Col xs={12} style={{ padding: '5px' }}><font style={{ fontFamily: 'Poppins', color: '#ffffff', fontWeight: '400', fontSize: '15px' }}> {bannerText}</font></Col></Row>}
        </Container>
    )
}
export default HeaderDetails;